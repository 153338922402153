@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.form-label {
  margin-bottom: 0.5rem;
  color: #959ca9;
}

.col-form-label {
  padding-top: calc(0.6rem + 1px);
  padding-bottom: calc(0.6rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.7;
  color: #959ca9;
}

.col-form-label-lg {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  font-size: 0.85rem;
}

.col-form-label-sm {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  font-size: 0.7rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.6rem;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.6rem 1rem;
  font-size: 16px;
  /* font-weight: 500; */
  line-height: 1.7;
  color: #60697b;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid rgb(0 0 0 / 24%);
  border-radius: 0.4rem;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #60697b;
  background-color: var(--bs-body-bg);
  border-color: #9fbcf0;
  outline: 0;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04), unset;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.7em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder {
  color: #959ca9;
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.6rem 1rem;
  margin: -0.6rem -1rem;
  margin-inline-end: 1rem;
  color: #60697b;
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6rem 0;
  margin-bottom: 0;
  line-height: 1.7;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.7em + 0.8rem + 2px);
  padding: 0.4rem 0.6rem;
  font-size: 0.7rem;
  border-radius: 0.4rem;
}

.form-control-sm::file-selector-button {
  padding: 0.4rem 0.6rem;
  margin: -0.4rem -0.6rem;
  margin-inline-end: 0.6rem;
}

.form-control-lg {
  min-height: calc(1.7em + 1.3rem + 2px);
  padding: 0.65rem 1.4rem;
  font-size: 0.85rem;
  border-radius: 0.4rem;
}

.form-control-lg::file-selector-button {
  padding: 0.65rem 1.4rem;
  margin: -0.65rem -1.4rem;
  margin-inline-end: 1.4rem;
}

textarea.form-control {
  min-height: calc(1.7em + 1.2rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.7em + 0.8rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.7em + 1.3rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.7em + 1.2rem + 2px);
  padding: 0.6rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.4rem;
}

.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 0.4rem;
}

.form-control-color.form-control-sm {
  height: calc(1.7em + 0.8rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.7em + 1.3rem + 2px);
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath fill='%2360697b' d='M22.667 12.227c-0.241-0.24-0.573-0.388-0.94-0.388s-0.699 0.148-0.94 0.388l0-0-4.787 4.72-4.72-4.72c-0.241-0.24-0.573-0.388-0.94-0.388s-0.699 0.148-0.94 0.388l0-0c-0.244 0.242-0.394 0.577-0.394 0.947s0.151 0.705 0.394 0.947l5.653 5.653c0.242 0.244 0.577 0.394 0.947 0.394s0.705-0.151 0.947-0.394l0-0 5.72-5.653c0.244-0.242 0.394-0.577 0.394-0.947s-0.151-0.705-0.394-0.947l-0-0z'%3E%3C/path%3E%3C/svg%3E");
  display: block;
  width: 100%;
  padding: 0.6rem 2rem 0.6rem 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.7;
  color: #959ca9;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px 20px;
  border: 1px solid rgba(8, 60, 130, 0.07);
  border-radius: 0.4rem;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: rgba(8, 60, 130, 0.1);
  outline: 0;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04), unset;
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #959ca9;
}

.form-select-sm {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.6rem;
  font-size: 0.7rem;
  border-radius: 0.4rem;
}

.form-select-lg {
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  padding-left: 1.4rem;
  font-size: 0.85rem;
  border-radius: 0.4rem;
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23cacaca' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.36rem;
  padding-left: 1.55rem;
  margin-bottom: 0.125rem;
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: left;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.55rem;
}

.form-check-reverse {
  padding-right: 1.55rem;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.55rem;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: #fefefe;
  flex-shrink: 0;
  width: 29px;
  height: 29px;
  margin-top: 0.325rem;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(8, 60, 130, 0.1);
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: rgba(8, 60, 130, 0.1);
  outline: 0;
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #2db0fb;
  border-color: #2db0fb;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("../public/images/checkbox-icon.svg");
  background-size: 16px;
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='1.5' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #04255c;
  border-color: #04255c;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%239fbcf0'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"]
  .form-switch
  .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fefefe, unset;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fefefe, unset;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #04255c;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #c5d7f6;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #04255c;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #c5d7f6;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(1rem + 2px);
  color: #000;
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 2;
  height: 100%;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  padding-top: 3px;
}

.form-floating > label.address {
  position: absolute;
  top: 10px;
  left: 40px;
  z-index: 2;
  height: 100%;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1.4rem;
}

.form-floating > .form-control.street-field,
.form-floating > .form-control-plaintext {
  padding: 1rem 2.6rem;
  padding-right: 10px;
}

.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.form-floating > .form-select {
  padding-top: 1.3rem;
  padding-bottom: 0.2rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: #717171;
  padding-top: 4px;
  font-size: 13px;
  transform: scale(0.8) translateY(-0.4rem) translateX(0.2rem);
}

.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 0.6rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: 0.4rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 1);
  transform: scale(0.8) translateY(-0.4rem) translateX(0.2rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #60697b;
}

.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.7;
  color: #60697b;
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: 1px solid rgba(8, 60, 130, 0.07);
  border-radius: 0.4rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.65rem 1.4rem;
  font-size: 0.85rem;
  border-radius: 0.4rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.4rem 0.6rem;
  font-size: 0.7rem;
  border-radius: 0.4rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.7rem;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.8rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: 0.4rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.7em + 1.2rem);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23198754' d='M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.3rem) center;
  background-size: calc(0.425em + 0.6rem) calc(0.425em + 0.6rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.7em + 1.2rem);
  background-position: top calc(0.425em + 0.3rem) right calc(0.425em + 0.3rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23198754' d='M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z'/%3E%3C/svg%3E");
  padding-right: 4.5rem;
  background-position: right 0.75rem center, center right 2rem;
  background-size: 20px 20px, calc(0.425em + 0.6rem) calc(0.425em + 0.6rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.7em + 1.2rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid,
.input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.7rem;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.8rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: 0.4rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.7em + 1.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.3rem) center;
  background-size: calc(0.425em + 0.6rem) calc(0.425em + 0.6rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.7em + 1.2rem);
  background-position: top calc(0.425em + 0.3rem) right calc(0.425em + 0.3rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.5rem;
  background-position: right 0.75rem center, center right 2rem;
  background-size: 20px 20px, calc(0.425em + 0.6rem) calc(0.425em + 0.6rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.7em + 1.2rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid,
.input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.7rem;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.8rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: 0.4rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.7em + 1.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.3rem) center;
  background-size: calc(0.425em + 0.6rem) calc(0.425em + 0.6rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.7em + 1.2rem);
  background-position: top calc(0.425em + 0.3rem) right calc(0.425em + 0.3rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.5rem;
  background-position: right 0.75rem center, center right 2rem;
  background-size: 20px 20px, calc(0.425em + 0.6rem) calc(0.425em + 0.6rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.7em + 1.2rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

input#zipCode::-webkit-outer-spin-button,
input#zipCode::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input#zipCode[type="number"] {
  -moz-appearance: textfield;
}
